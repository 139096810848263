// Fonts ========================================

.alt-font { font-family: $alt-font; }

h2 {
	font-size: 1.5em;
	margin-bottom: .4em;
	@include breakpoint($sm){
		font-size: 2.2em;
	}
}
