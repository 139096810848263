// Mixins ========================================

// ROOT ==============================

// TYPOGRAPHY ==============================
@mixin font-size($base-fontsize: 16){
	font-size : #{$base-fontsize}px;
	font-size : #{$base-fontsize / 10}rem;
}

// Responsive Fonts
@function em($target, $context: $base-fontsize) {
	@if $target == 0px { @return 0 }
	@return $target / $context + 0em;
}

// Vertical Rhythm
@function lh($amount: 1, $context: $base-fontsize) {
   @return em($base-lineheight * $amount, $context);
}


// COLOR ==============================

@function black($opacity){
	@return rgba(0,0,0,$opacity)
}

@function white($opacity){
	@return rgba(255,255,255,$opacity)
}


//
@function trans($color, $percentage) {
	@return lighten($color, (1-$percentage)*5%);
}
@function transd($color, $percentage) {
	@return darken($color, (1-$percentage)*5%);
}

//	@include tran(TYPE, COLOR, PERECNTAGE, SHADE)
@mixin tran($type, $color, $percentage, $shade: ligthen) {
  @if $shade == lighten {
    @if $type == color {
      color: trans($color, $percentage);
      color: rgba($color, $percentage);
    }
    @if $type == bg {
      background-color: trans($color, $percentage);
      background-color: rgba($color, $percentage);
    }
  }
  @else {
     @if $type == color {
      color: transd($color, $percentage);
      color: rgba($color, $percentage);
    }
    @if $type == bg {
      background-color: transd($color, $percentage);
      background-color: rgba($color, $percentage);
    }
  }
}

// @include gradient(FROM, TO)
@mixin gradient($from, $to) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient($from, $to);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient($from, $to);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient($from, $to);
}


// TEXTURE ==============================

// @include rounded(VALUE)
@mixin rounded($radius: 4px) {
	   -moz-border-radius : $radius;
	-webkit-border-radius : $radius;
	    -ms-border-radius : $radius;
	        border-radius : $radius;
}


// @include box-shadow(TOP, LEFT, BLUR, COLOR, SPREAD, INSET)
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow : inset $top $left $blur $spread $color;
       -moz-box-shadow : inset $top $left $blur $spread $color;
            box-shadow : inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow : $top $left $blur $spread $color;
       -moz-box-shadow : $top $left $blur $spread $color;
            box-shadow : $top $left $blur $spread $color;
  }
}

// @include fancy-shadow
@mixin fancy-shadow() {
	   -moz-box-shadow : 0 1px 8px rgba(0,0,0,0.05), 0 0 2px rgba(0,0,0,0.075);
	-webkit-box-shadow : 0 1px 8px rgba(0,0,0,0.05), 0 0 2px rgba(0,0,0,0.075);
	        box-shadow : 0 1px 8px rgba(0,0,0,0.05), 0 0 2px rgba(0,0,0,0.075);
}



// @include letterpress(PERCENTAGE VALUE)
@mixin letterpress($opacity: 0.5){
	text-shadow: 0 1px 0 white($opacity);
}
// @include emboss(PERCENTAGE VALUE)
@mixin emboss($opacity: 0.5){
	text-shadow: 0 1px 0 black($opacity);
}


// @include hoverglow()
@mixin hoverglow($opacity: 0.2) {
	text-shadow: 0 0 10px white($opacity);
}

// Opacity
@mixin img-opacity($trans) {
          filter : alpha(opacity=$trans * 100);
      -ms-filter : "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$trans * 100})";
    -moz-opacity : $trans;
  -khtml-opacity : $trans;
         opacity : $trans;
}

// ELEMENTS ==============================

// .background-size(VALUE VALUE);
@mixin background-size($size){
	-webkit-background-size : $size;
	   -moz-background-size : $size;
	     -o-background-size : $size;
	        background-size : $size;
}

// .background-clip(VALUE); (border-box, padding-box, content-box)
@mixin background-clip($clip) {
	-webkit-background-clip : $clip;
	   -moz-background-clip : $clip;
	        background-clip : $clip;
}

// The micro clearfix http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
	*zoom:1;
	&:before,
	&:after {
		content:"";
		display:table;
	}
	&:after {
		clear:both;
	}
}

// For image replacement
@mixin hide-text() {
	text-indent : 100%;
	white-space : nowrap;
	overflow : hidden;
}

// Hide from visual and speaking browsers
@mixin hidden() {
	display : none !important;
	visibility : hidden;
}

// Hide but maintain layout
@mixin invisible() {
	visibility : hidden;
}

// @include resize(VALUE) (none, both, horizontal, vertical, inherit)
@mixin resize($direction: both) {
	resize : $direction;
	overflow : auto;
}

// Hidden but available to speaking browsers
@mixin visuallyhidden() {
	overflow : hidden;
	position : absolute;
	clip : rect(0 0 0 0);
	height : 1px;
	width : 1px;
	margin : -1px;
	padding : 0;
	border : 0;
	// Make visuallyhidden focusable with a keyboard
	&.focusable {
		&:active,
		&:focus {
			position : static;
			clip : auto;
			height : auto;
			width : auto;
			margin : 0;
			overflow: visible;
		}
	}
}

@mixin border-radius($radius: 0) {
	-webkit-border-radius : $radius;
	   -moz-border-radius : $radius;
	        border-radius : $radius;
  -webkit-background-clip : padding-box;
	 -moz-background-clip : padding;
	      background-clip : padding-box;
}

// @include transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);
@mixin transition($transition) {
	-webkit-transition : $transition;
	   -moz-transition : $transition;
	    -ms-transition : $transition;
	        transition : $transition;
}

// @include transform(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);
@mixin transform($transform) {
	-webkit-transform : $transform;
	   -moz-transform : $transform;
  	    -ms-transform : $transform;
  	     -o-transform : $transform;
    		transform : $transform;
}

@mixin transform-origin($origin) {
	-webkit-transform-origin : $origin;
	   -moz-transform-origin : $origin;
	    -ms-transform-origin : $origin;
		 -o-transform-origin : $origin;
			transform-origin : $origin;

}

// LAYOUT ==============================

// Responsive Percentages
@function perc($width, $container-width: $max-width) {
	@return percentage($width / $container-width);
}


// Semantic.gs // for SCSS: http://sass-lang.com/

// Utility function — you should never need to modify this
@function gridsystem-width($columns:$columns) {
	@return ($column-width * $columns) + ($gutter-width * $columns);
}

// GRID
@mixin row($columns:$columns) {
	display: block;
	width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns));
	margin-left: $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1);
	margin-right: $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1);
	// *width: $total-width*(($gutter-width + gridsystem-width($columns))/gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*((($gutter-width*.5)/gridsystem-width($columns))*-1)-$correction;
	@include clearfix();
}
@mixin column($x,$columns:$columns) {
	display: inline;
	float: left;
	width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns));
	margin-left: $total-width*(($gutter-width*.5)/gridsystem-width($columns));
	margin-right: $total-width*(($gutter-width*.5)/gridsystem-width($columns));
	// *width: $total-width*(((($gutter-width+$column-width)*$x)-$gutter-width) / gridsystem-width($columns))-$correction;
	// *margin: 0 $total-width*(($gutter-width*.5)/gridsystem-width($columns))-$correction;
}
@mixin push($offset:1) {
	margin-left: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}
@mixin pull($offset:1) {
	margin-right: $total-width*((($gutter-width+$column-width)*$offset) / gridsystem-width($columns)) + $total-width*(($gutter-width*.5)/gridsystem-width($columns));
}




/**
 * Media Queries
 *
 * Source: https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
 */
//	Setup breakpoints
$xxs: 	( max: 479px );
$xs: 	( min: 480px );
$sm: 	( min: 600px );
$md: 	( min: 800px );
$lg: 	( min: 992px );
$xl: 	( min: 1382px );
$xxl: 	( min: 1824px );
$sm-only: ( min: map-get($sm, min), max: map-get($md, min) - 1 );
$md-only: ( min: map-get($md, min), max: map-get($lg, min) - 1 );

// Create mixin
@mixin breakpoint($map) {
	$query:;
	@if map-has-key($map, min) {
		$query: append($query, "(min-width: #{map-get($map, min)})")
	}
	@if map-has-key($map, min) and map-has-key($map, max) {
		$query: append($query, "and")
	}
	@if map-has-key($map, max) {
		$query: append($query, "(max-width: #{map-get($map, max)})")
	}
	@media screen and #{$query} { @content; }
}

