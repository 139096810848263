
/* Modernizr =================================================== */

.js {
	&.cssanimations{
		#masthead,
		.banner-text,
		.basic-content,
		.form-container,
		.cta-bar,
		.main-footer
		 {
			opacity: 0;
		}

	}

}
/* audio */
/* applicationcache */
/* backgroundsize */
/* borderimage */
/* borderradius */
/* boxshadow */
/* canvas */
/* canvastext */
/* cssanimations */
.cssanimations {
	.ls-branding {
		a {
			&:hover {
			.ls-logotype {
				left: 60px;
			}
		}
		}
	}
}
/* csscolumns */
/* cssgradients */
/* cssreflections */
/* csstransforms */
/* csstransforms3d */
/* csstransitions */
/* draganddrop */
/* flexbox */
/* fontface */
/* generatedcontent */
/* geolocation */
/* hashchange */
/* history */
/* hsla */
/* indexeddb */
/* inlinesvg */
/* localstorage */
/* multiplebgs */
/* opacity */
/* postmessage */
/* rgba */
/* sessionstorage */
/* smil */
/* svg */
/* svgclippaths */
/* textshadow */
/* touch */

.no-touch.cssanimations {
	.ls-branding {
		.ls-logotype {
			opacity: 0;
		}
	}
}

.touch.cssanimations {
	.ls-branding {
		.ls-logotype {
			display: none;
		}
	}
}

/* video */
/* webgl */
/* websqldatabase */
/* websockets */
/* webworkers */
