/**
 *
 * Master Stylesheet
 *
 * Project: 	Financial Assistance Network
 * Version: 	1.0
 *
 *
 */

/*

HELPERS
--------------------
Setting up variables, helpful mixins and functions
-------------------- */
@import "helpers/variables";	// Variables
@import "helpers/mixins";        // Mixins

/*

NORMALIZE
--------------------
Clean slate
-------------------- */
@import "base/normalize";

/*

VENDORS
--------------------
Bootstrap, jQueryUI, etc
-------------------- */


/*

BASE
--------------------
Fonts and Global settings
-------------------- */
// Fonts
@import "base/fonts";

/*

LAYOUT
--------------------
Basic site structure. Macro
-------------------- */
@import "layout/site";


/*

COMPONENTS
--------------------
Smaller modular components. Micro
-------------------- */
@import "components/media"; 		// Media: Images, etc.
@import "components/forms"; 		// Forms
@import "components/modals";		// Modals

/*

MODERNIZR
--------------------
Tweaking the things
-------------------- */
@import "modernizr";

/*
PRINT
--------------------
Basics to clean up page if printed for some strange reason
-------------------- */
@media print {
    @import "print";
}

/*

MOBILE MEDIA QUERIES
--------------------
Need to add to specific areas
-------------------- */

// Mobile
@import "device-mobile";
// Table
@import "device-tablet";
