@-webkit-viewport { width : device-width; }
@-moz-viewport { width : device-width; }
@-ms-viewport { width : device-width; }
@-o-viewport { width : device-width; }
@viewport { width : device-width; }



/**
 *
 * Opinionated Defaults
 * ------------------------------
 */

html, button, input, select, textarea { color: $text-color; font-family: $base-font; }

html {
	background : $body-color;
	font-size : 62.5%;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten($text-color, 75%);
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
	height: 100%;
}

body {
	margin : 0;
	padding : 0;
	width : 100%;
	background-color : transparent;
	font-family : $base-font;
	line-height : $base-lineheight;
	color : $text-color;
	font-size: $base-fontsize;
	line-height: lh(2);
	// width: 100%;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include clearfix();

	@include breakpoint($sm) {
		-webkit-text-size-adjust: none;
		-ms-text-size-adjust: none;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

* {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}

::-moz-selection { background: #b3d4fc; text-shadow: none; }
::selection { background: #b3d4fc; text-shadow: none; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }


/**
 *
 * Helper Classes
 * ------------------------------
 */

.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	/* IE 6/7 fallback */ *text-indent: -9999px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 100%;
	}
}

.hidden {
	@include hidden;
}
.visuallyhidden {
	@include visuallyhidden;
}
.invisible {
	@include invisible;
}
.clearfix {
	@include clearfix();
}


/* Browser Happy prompt  */
.browsehappy {
	margin: 0;
	padding: lh() 1em;
	background: $ls-blue;
	color: $ls-purple;
	z-index: 4000;
	text-align: center;
	border: 10px solid darken( $ls-blue, 5% );

	h4 {
		color: #fff;
		line-height: lh(1.75);
		margin-bottom: lh(1);
		@include font-size(20);
		strong {
			font-variant: small-caps;
			font-size: 1.15em;
		}
	}
	p {
		margin: 0;
		@include font-size(14);
	}
	a {
		color: $ls-purple;
		font-weight: 700;
		border-bottom: 2px solid darken( $ls-blue, 5% );

		&:hover {
			color: darken( $ls-blue, 20% );
			border-color: darken( $ls-blue, 10% );
		}
	}
}


/**
 *
 * Basic Site Structure
 * ------------------------------
 */

.loader{
  float: left;
  margin-top: auto;
  width: 88%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  position: relative;
  @include breakpoint($sm){
  	width: 48%;
  }

}

.progress-bar{
  width: 0%;
  text-align: right;
  height: inherit;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
	background: #86c440;
}

.percentage{
  position: relative;
  top: 2px;
  font-weight: bold;
  font-size: 16px;
  padding-right: 1em;
}
 
.form-header-h3 {
	margin-top: 0;
}
	
.new-logo-width { 
	width: 100%;
	max-width: 75%;
	margin: auto;
}
	
.container-survey {
		margin-top: 2em;
		text-align: center;
		color: rgb(90, 101, 115);
		float: left;
	    border-color: rgb(158, 167, 178);
		outline: none;
		background-color: rgb(255, 255, 255);			
		border-width: 1px;
		border-style: solid;
		box-shadow: 1px 2px 3px;
		line-height: 1em;
		padding: 1.5em .5em 0em .5em;
		@include breakpoint($md) {
			width: 90%;
		}
}

.container-survey h3 {
			font-size: 1.9em;
			font-weight: 700;
			margin-bottom: 1em;
			margin-top: .5em;
			line-height: 1.3em;
}

.container-survey p {
		line-height: 1.3em;
		font-size: 25px;
		text-align: center;
}

	
@media screen and (min-width: 800px) {

	.new-logo-width {
		width: 25%;
		max-width: 50%;
	}

	.container-survey {
			margin-bottom: 1em;
			margin-top: 2em;
			text-align: center;
			color: rgb(90, 101, 115);
			float: left;
		    border-color: rgb(158, 167, 178);
    			outline: none;
    			background-color: rgb(255, 255, 255);
    			box-shadow: 1px 2px 3px;			
			border-width: 1px;
			border-style: solid;
			line-height: 1.25em;
			padding: 1.5em 3.5em 0em 3.5em;
	}
	
	.container-survey h3 {
				font-size: 2.9em;
				font-weight: 700;
				margin-bottom: .5em;
				margin-top: .5em;
	}
	
	.container-survey p {
			line-height: 1.35em;
			font-size: 30px;
			text-align: center;
	}

}
 
.offer-container {
	border: 1px solid black;
	box-shadow: 2px 3px 5px #8d96a3;
	display: flex;
	flex-direction: column;
	margin-top: 1em;
	@include breakpoint($md) {
		display: grid;
		width: 100%;
		grid-template-areas: "image main button";
		grid-template-columns: 1fr 3fr 1fr;
		grid-gap: 10px;
		background-color: white;
		padding: 10px;
	}
}

.offer-container > div {
 	background-color: rgba(255, 255, 255, 0.8);
	padding: 20px 0;
	font-size: 16px;
}

.offer-details {
	margin-bottom: 0 !important;
}

.offer-img {
	grid-area: image;
	@include breakpoint($md) {
		text-align: center;
    		margin: 1em 0;
    		height: 25px;
	}
}

.helper {
	@include breakpoint($md) {
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
}

.offer-copy {
	padding: 1em;
	text-align: left;
	grid-area: main;
}

.offer-cta {
	grid-area: button;
	vertical-align: middle;
    line-height: 100px;
}

.back-button {
	float: left;
	padding-right: .5em;
	@include breakpoint($sm){
		width: 25%;
	}
}

.back-button > a, .forward-button > a {
	text-decoration: underline;
	font-size: 1.3em;
	font-weight: 600;
}

.forward-button {
	visibility: hidden;
	padding-left: .5em;
	@media (min-width: 800px) {
		float: right;
	}
}

.btn-survey-holder {
	width: 25%;
	margin: 0 auto;
}

.progress-nav {
	padding-top: 1em;
}

#debt-amount {
	font-size: 1.5em;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #08384f;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #08384f;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #08384f;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
  background: #08384f;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
	background: #ffffff;
	cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #08384f;
}

input[type=range]:focus::-ms-fill-upper {
  background: #08384f;
}

.radio-container {
	text-align: center;
	width: 75%;
    margin: 0 auto;
}

.radio-container label {
	background: #efefef;
    color: #364580;
    font-size: 18px;
    border-radius: 0;
    border: 1px solid #ccc;
    display: inline-block;
    margin: 10px;
    width: 200px;
}

.radio-container .payment-radio {
	line-height: 1.2em;
	background: #efefef;
    color: #364580;
    font-size: 18px;
    border-radius: 0;
    border: 1px solid #ccc;
    display: block;
    margin: 10px auto;
    width: 200px;
    padding: 10px;
    @include breakpoint($sm){
    		width: 250px;
    }
}

.radio-container label:hover, .radio-container .payment-radio:hover {
	background: #d4cece;
	-webkit-box-shadow: 2px 3px 4px rgba(0, 0, 0, .35);
	box-shadow: 2px 3px 4px rgba(0, 0, 0, .35);
}

.radio-container .radio {
	padding: 10px;
	cursor: pointer;
	text-align: center;
}

.selected {
	border: 2px solid #86c440!important;
    -webkit-box-shadow: 0 0 5px #86c440!important;
    box-shadow: 0 0 5px #86c440!important;
}

#survey-slider {
	width: 50%;
}

.mt-1 {
	margin-top: 1em !important;
}

.pb-1 {
	padding-bottom: 1em !important;
}

[class*="col-"] {
	float:left;
	margin-right: 3%;
	-webkit-box-sizing:border-box; 
	-moz-box-sizing:border-box; 
	box-sizing:border-box;
	&:last-child {
		margin-right:0%;
	}
}

.col > img {
	height: 80px;
	width: 75%;
	margin: 0 auto;
}

.col > a {
	font-size: 1.5em;
}

.row {
	display: table;
	@extend .clearfix;
	text-align: center;
	margin-top: 5%;
	@include breakpoint($sm) {
		display: flex;
	}
}

.row:after {
    content: "";
    display: flex;
    clear: both;
}

.col {
	float: left;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	padding: 1em;
    outline: none;
    border-width: 1px;
    border-style: solid;
    line-height: 1.25em;
    margin-bottom: 1em;
    @include breakpoint($sm) {
		margin-top: 0;
	}
}

.col-4 {
	@include breakpoint($sm) {
		width: 31.33333333333333%;
	}
}

.col-6 {
	@include breakpoint($sm) {
		width:48.5%;
	}
}

row h2 {
	font-size: 1.3em;
}

row h3 {
	font-size: 1.2em;
}

.col-4 h1, .col-6 h1 {
	font-weight: 600;
}

.col-4 p, .col-6 p {
	padding-top: .5em;
	margin-top: auto;
}

.btn-bottom {
	margin-top: auto;
}

.cssanimations {
	.preload {

		* { @include transition( none !important ); }

		#masthead,
		.basic-content,
		.banner-text,
		.form-container,
		.cta-bar,
		.main-footer
		 {
			opacity: 0;
		}
	}
}

.wrapper,
.wide-wrapper {
	padding-left: 5%;
	padding-right: 5%;
	width: auto;
	position: relative;
	@include clearfix();

	@include breakpoint($sm) {
		padding-left: 2.5%;
		padding-right: 2.5%;
	}
	@include breakpoint($md) {
		padding-left: 1.5%;
		padding-right: 1.5%;
	}
}

@include breakpoint($lg) {
	.wrapper {
		width: 960px;
		padding-left: 0;
		padding-right: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

@include breakpoint($xl) {
	.wrapper {
		width: 1200px;
	}
}




/**
 *
 * Basic Site Typography
 * ------------------------------
 */

p,
li {
	@include font-size(16);
	line-height: lh(3);
	margin-bottom: lh(3);
}

nav li {
	margin: 0;
}

a {
	color: $linkcolor;
	text-decoration: none;
	@include transition( color 0.1s ease-out);

	&:hover {
		color: $linkcolorhover;
	}
}


/**
 * Masthead
 */
#masthead {
	@extend .clearfix;
	background: darken( $ls-purple, 8% );
	padding-left: 5em;
	height: 3.5em;
	z-index: 10;


	h1 {
		padding-top: lh(1.5);
		color: #fff;
		float: left;
		@include font-size(14);
	}

	.lp-phone {
		padding-top: lh(1);
		float: right;
	}

	@include breakpoint($md) {
		padding-left: 6em;

		h1 {
			@include font-size(18);
		}
	}
}

// Law Scout branding
.ls-branding {
	background: $ls-purple;
	display: block;
	position: relative;
	float: left;
	margin-left: -5em;
	height: 3.5em;
	width: 4.5em;

	a {
		padding: lh(1) 0.5em;
		display: block;


	}

	div {
		background-color: $ls-purple;
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		@include background-size( contain );
	}

	.icon-logomark {
		z-index: 102;
		margin-left: .6em;
		margin-top: .54em;
		width: 52px;
		height: 38px;

		svg {
			width: 3.25em;
			margin-top: lh(0.75);
		}

		.fill {
			fill: #fff;
		}

	}
	.ls-logotype {
		width: 200px;
		height: 3.5em;
		z-index: 101;
		left: 4.5em;
		height: 3.5em;

		svg {
			width: 80%;
			margin-top: lh(1.75);
		}

		.fill {
			fill: #fff;
		}
	}


	@include breakpoint($md) {
		margin-left: -6em;
	}

}

.formErrorArrow {
	display: none;
}

.formError .formErrorContent {
	font-family: 'Open Sans', Candara, Calibri, Segoe, "Segoe UI", Optima, Arial, sans-serif;
	line-height: 1.25em;
	font-size: 13px;
	border: none;
	box-shadow: 0 0 6px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 6px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);
}





/**
 * Banner Text
 */
.banner-text {
	padding-top: lh(2);
	text-align: center;


	h2 {
		line-height: lh(1.5);
		color: $white;
		margin-bottom: lh(1);
		font-weight: 700;
		@include font-size(20);
	}

	h3 {
		color: $white;
		font-weight: 700;
		margin-bottom: lh(2);
		line-height: lh(2);
		@include font-size(16);
	}



	ul {
		margin: 0;
		margin-bottom: lh(2);
		text-align: left;

		li {
			margin: 0 0 lh() 0;
			padding: 0;
			color: $white;
			position: relative;
			@include font-size(15);


		}

		&.checks {

			li {
				padding-left: 2.5em;

				.icon-checkmark {
					display: block;
					position: absolute;
					left: -2px;
					top: -2px;
					width: 2em;
					height: 2em;

					.fill {
						fill: $ls-green;
					}
				}
			}

			&.lawyerlander {
				color: $white;
			}
		}

	}





	@include breakpoint($sm) {
		ul {
			margin-left: 20%;
		}
	}

	@include breakpoint($md) {
		text-align: left;
		padding-top: lh(5);
		@include column(8);

		h2 {
			letter-spacing: -0.06em;
			font-weight: 300;
			@include font-size(40);
		}

		h3 {
			@include font-size(20);
		}

		ul {
			margin-left: 0;
			margin-bottom: lh(6);

			li {
				@include font-size(18);
			}
		}

	}

	@include breakpoint($lg) {
		@include column(7);
	}


	@include breakpoint($xl) {
		padding-top: lh(10);

		h3 {
			letter-spacing: -0.04em;
			@include font-size(25);
		}
	}

	&.lawyerlander {
		ol {
			list-style-type: disc;
			padding-left: 25px;
			color: $white;
			text-align: left;
			margin-top: 1em;
		}

		ol li {
			margin-bottom: 1em;
		}

		ul li {
			color: $white;
		}

		p {
			color: $white;
			text-align: left;
		}

		@include breakpoint($md) {
			text-align: left;
			padding-top: lh(5);
			@include column(8);

			h2 {
				letter-spacing: -0.06em;
				font-weight: 300;
				@include font-size(35);
			}

			h3 {
				@include font-size(20);
			}

			ul {
				margin-left: 0;
				margin-bottom: lh(2);

				li {
					@include font-size(18);
				}
			}

		}

		@include breakpoint($lg) {
			@include column(7);
		}


		@include breakpoint($xl) {

			h2 {
				@include font-size(60);
			}

			h3 {
				letter-spacing: -0.04em;
				@include font-size(25);
			}
		}

	}
}

.bottom-wrap {
  @include breakpoint($md) {
    margin-top: 2em;
  }

  @include breakpoint($xl) {
    margin-top: 0;
  }
}

/**
 * Form Container
 */
.form-container {
	background: #fff;
	padding-top: lh();
	margin-bottom: lh(2);
	@include fancy-shadow();

	header {
		padding: lh() 0.5em;
		padding-bottom: 0;
		text-align: center;
		margin-bottom: lh(1);
		border-bottom: 1px solid #E7E7E7;

		p {
			color: $white;
			margin-bottom: 0;
			text-transform: uppercase;
			@include font-size(16);
		}

		h3 {
			color: rgb( 43,113,157 );
			font-weight: 700;
			margin-bottom: lh();
			line-height: 1.5em;
			@include font-size(20);
		}

		&:after {
			width: 100px;
			height: 9px;
			content: "";
			background-color: #E7E7E7;
			display: block;
			margin: 0 auto -5px;
		}
	}

	.form-fields,
	.btn-holder {
		padding: lh() 0.75em;
	}
	.msg-module {
		margin: 0 0.5em lh();
	}

	.btn {
		//width: 100%;
	}



	@include breakpoint($md) {
		margin-top: 7em;
		z-index: 200;
		@include column(8);
		position: absolute;
		right: 0;


		header {
			h3 {
				@include font-size(20);
			}
		}
		.form-fields,
		.btn-holder {
			padding-left: 1.25em;
			padding-right: 1.25em;
		}
		.msg-module {
			margin-left: 1em;
			margin-right: 1em;
		}
	}

	@include breakpoint($lg) {
		@include push();
	}

	@include breakpoint($xl) {

		header {
			padding-top: lh(2);

			h3 {
				margin-bottom: lh(1.5);
				@include font-size(25);
			}
		}
		.form-fields,
		.btn-holder {
			padding-left: 2em;
			padding-right: 2em;
		}
	}
}


.lawyerlander-response {
	@include breakpoint($md) {
		margin-top: lh(8);
	}


	@include breakpoint($lg) {
		margin-top: lh(7);
	}

	@include breakpoint($xl) {
		margin-top: lh(10);
	}
}

.post-response-success {
	display: none;
	h3 {
		color: $white;
		font-weight: 700;
		margin-bottom: lh();
		margin-left: lh();
		line-height: lh(2);
		@include font-size(25);

	}
	&:after {
		width: 100px;
		height: 9px;
		content: "";
		background-color: #E7E7E7;
			display: none;
		margin: 0 auto -5px;
	}

}

.cta-bar {
	background-color: darken( $ls-blue, 20% );
	padding-top: lh(3);
	padding-bottom: lh(2);
	text-align: center;
	position: relative;
	overflow: hidden;

	h3 {
		font-weight: 700;
		color: $white;
		margin-bottom: lh(1);
		line-height: lh(0.5);
		@include font-size(25);
	}
	p {
		color: $white;
		font-weight: 700;
		margin:0;
		line-height: lh(1.75);
		@include font-size(12);
	}

	.ls-logomark-alt {
		position: absolute;
		top: -100%;
		right: 0;
		left: 0;
		margin: 0 auto;
		width: 90%;

		.fill {
			fill: darken( $ls-blue, 22% );
		}

	}

	@include breakpoint($md) {
		text-align: left;

		h3 {
			@include font-size(34);
		}

		p {
			@include font-size(16);
		}

		.ls-logomark-alt {
			width: 50%;
			top: -200%;
		}

	}
}

.basic-content {
	background: #fff;
	padding: lh(6) 1em lh();
	margin-top: lh(2);
	margin-bottom: lh(2);
	color: $white;
	@include fancy-shadow();

	h2 {
		font-weight: 700;
		margin-bottom: lh(1.5);
		line-height: lh(2);
		color: $white;
		@include font-size(24);
	}

	p {
		margin-bottom: lh(1.5);
		@include font-size(14);
	}

	@include breakpoint($md) {
		@include column(12);
		@include push(2);

		h2 {
			@include font-size(28);
		}
	}
	@include breakpoint($lg) {
		@include column(10);
		@include push(3);

		h2 {
			@include font-size(34);
		}

		p {
			@include font-size(16);
		}
	}
}

// Thank You Page resources
.resources {
	display: table;
	width: 100%;
	margin: lh(4) 0 lh();
	border-collapse: collapse;
	border: 1px solid $body-color;

	li {
		display: table-row;
		border: 1px solid $body-color;
		margin: 0;
		font-weight: 700;
		line-height: lh(2);
		text-align: center;
		@include font-size(14);
		@include transition( background 0.1s ease-out);

		&:hover {
			background: lighten( $body-color, 5% );
			border-color: darken( $body-color, 2% );
		}


		div {
			padding: lh(1) 0.5em;
		}
	}

	@include breakpoint($md) {

		li {
			text-align: left;
			@include font-size(16);
		}

		div {
			display: table-cell;
			width: 100%;
			vertical-align: top;

			&:first-child {
				padding-left: 1em;
			}
			&:last-child {
				padding-right: 0.75em;
			}
		}
	}

	@include breakpoint($xl) {
		li {
			@include font-size(18);
		}
	}
}

footer.main-footer {
	padding-top: lh(6);
	text-align: center;
	color: $white;

	a {
		color: $white;

		&:hover {
			color: $white;
		}
	}

	.landerfooter {
		line-height: .1em;
		color: #000;
		text-align:center;
		a {
			color: #000;
			font-size: .8em;
			text-decoration: underline;
		}
	}

	ul {
		margin: 0;

		li {
			display: inline;
			margin: 0 1em lh() 0;
			@include font-size(12);

			&:after {
				display: inline-block;
				content: '';
				background: desaturate( lighten( $ls-purple, 50% ), 20% );
				width: 0.5em;
				height: 0.5em;
				margin-left: 1em;
				@include rounded(30px);
			}

			&:last-child:after {
				display: none;
			}

		}
	}
	p {
		clear: left;
		@include font-size(12);
	}


	@include breakpoint($md) {
		ul {
			li {
				@include font-size(14);

			}
		}
		p {
			@include font-size(14);
		}

		.lp & {
			text-align: left;
			ul {
				li {
					float: left;
				}
			}
		}


	}

}

.advert_section {
	padding: 9em;
	margin-top: 6em;
	color: $white;
	@include font-size(10);
}

.mobile-only {
	display: block;
}

.desktop-only {
	display: none;
	margin-right: 20px;
	margin-left: 20px;
}

.additional-info-mobile {
  margin-top: 1em;
  display: block;
  color: $white;
}

.additional-info-nonmobile {
  display: none;
}

.additional-info-mobile h3 {
    color: $white;
    margin-bottom: 1em;
    line-height: 1.25em;
    font-size: 16px;
    font-size: 1.6rem;
  font-weight: 900;
  text-align: center;
  margin-top: -1em;
}

.additional-info-nonmobile h3 {
  text-align: center;
  margin-bottom: .5em;
}

.logo {
	max-width: 55%;
	@include breakpoint($sm){
    		max-width: 25%;
    	}
}

@media screen and (min-width: 800px) {

  .mobile-only {
  	display: none;
  }
  
  .desktop-only {
  	display: block;
  }

  .additional-info-mobile {
    display: none;
  }

  .additional-info-nonmobile {
    display: block;
  }

  .logo {
  	display: block;
  	max-width: 75%;
  }
}

#stateDD {
	display: none;
}



