/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

	.touch {
		// Home
		.home {
			#main-wrapper {
				margin: 0 !important;
				padding: 0 !important;
			}
			.home-page {
				padding-top: lh(8);
			}
		}
	}

	.advert_section {
		padding-top: 1em;
		color: darken($grey, 25%);
		@include font-size(10);

	}

	.radio-inputs {
		@include column(16);
		label {
			span {
				width: 20%;
			}
		}
	}

}

// /* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {


	.touch {
		.home {
			#main-wrapper {
				height: 320px;
			}
			#main-footer {
				display: none;
			}
		}

	}
}

// /* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {

	.touch {
		.home {
			#main-wrapper {
				height: 480px;
			}
			.home-page {
				padding-top: lh(4);
			}
			#main-footer {
				height: lh(15);
			}

		}
		.logo-wrapper,
		#logo-home {
			margin-bottom: lh(1.5);
		}

		#footer-contact a {
			margin-right: 0;
		}
	}


}

// /* iPhone 5 (portrait)----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {

.touch {
	.home {
		#main-wrapper {
			height: 568px;
		}

	}
}


}
