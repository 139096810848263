/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {

.touch {

	// Typography

	// Structure
	// --------------------------------------------------------------
	// Home page
	.home {
		#main-wrapper {
			margin: 0 !important;
			padding: 0 !important;
		}

		.home-page {
			padding-top: lh(8);
		}

		#main-footer {
			display: block;
		}
	}
}


}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {

.touch {
	// =HOME
	// --------------------------------------------------------------
	.home {
		#main-wrapper {
			height: 768px;
		}
	}
}


}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {

.touch {
	// =HOME
	// --------------------------------------------------------------
	.home {
		#main-wrapper {
			height: 1024px;
		}
	}
}


}
